import React from 'react';
import GaleryView from "../view/GaleryView";
import axios from "axios";
import sanitizeHtml from "sanitize-html";
import Page from "./Page";
import Error404AdminView from "../view/Error404AdminView";

class GaleryPage extends Page {

    constructor(props) {
        super(props)
        const { match: { params } } = this.props;
        this.galeryId = params.galeryId;
        this.state = {
            loadFailed: false,
            loaded: false,
            images: [],
            loading: false,
            files: [],
            access_token: '',
            numFiles: 0,
            progress: {
                show: false,
                progress: 0
            },
            galery: {},
            sizes: [],
            name: null,
            modifySizeId: null,
            introHasUnsaved: false,
            newGreeting: null
        }
    }

    async componentDidMount() {
        try {
            let result = await axios.get(process.env.REACT_APP_API_URL + "/galery/"+this.galeryId,this.axiosConfig())
            if (result.data.data !== undefined && result.data.data.name !== undefined) {
                this.setState({loaded: true, galery: result.data.data, name: result.data.data.name, greeting: result.data.data.greeting});
            }
        } catch (e) {
            this.handleError(e);
            this.setState({loadFailed: true});
        }
        this.update();
        this.updateSizes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.name !== this.state.name) {
            window.changeTitle('Galéria: '+this.state.name);
            window.changeBreadcrumbs({
                up: [{title: "Galériák", link: "/admin/galeries"}],
                active: {title: "Galéria: "+this.state.name}
            });
        }
    }

    update = () => {
        this.setState({ loading:true },async () => {
            try {
                let result = await axios.get(process.env.REACT_APP_API_URL + '/galery/' + this.galeryId + '/images',this.axiosConfig())
                this.setState({loading: false, images: result.data.data, accessToken: result.data.access_token})
            } catch (e) {
                this.handleError(e);
                this.setState({loading:false})
            }
        });
    }



    onFilesChange = (files) => {
        this.setState({files,numFiles: files.length},() => {
            this.notify(this.state.numFiles+' file kiválasztva');
        });
    }

    onUpload = async (progress,finish) => {

        /*function timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }*/

        progress(0);
        for (let i = 0; i < this.state.files.length; i++) {
            progress((i*1.0 / this.state.files.length)*100.0)
            let file = this.state.files[i];
            var formData = new FormData();
            formData.append("file",file);
            try {
                let config = this.axiosConfig();
                await axios.post(process.env.REACT_APP_API_URL + "/galery/"+this.galeryId+"/images",formData,Object.assign(config,{
                    headers: {
                        ...config.headers,
                        'Content-Type': 'multipart/form-data'
                    }
                }))
            } catch (e) {
                this.handleError(e);
            }
        }
        progress(100);
        finish();
        this.notify('A képek feltöltése befejeződött');
        this.setState({ files: [], numFiles: 0 },() => {
            this.update();
        })

    }

    onDelete = async (id,name) => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + "/galery/"+this.galeryId+"/images/"+id,this.axiosConfig());
            this.notify('A kép törlése sikerült: '+name);
        } catch (e) {
            this.handleError(e);
        } finally {
            this.update();
        }
    }

    onDeleteAll = async () => {
        if (window.confirm("Törlöd az összes képet?")) {
            try {
                await axios.delete(process.env.REACT_APP_API_URL + "/galery/" + this.galeryId + "/images", this.axiosConfig());
                this.notify('Az összes kép törlése sikerült');
            } catch (e) {
                this.handleError(e);
            } finally {
                this.update();
            }
        }
    }

    filterGreeting(content) {
        return sanitizeHtml(content,{
            allowedTags: [ 'h1', 'h2', 'h3', 'b', 'i', 'em', 'strong', 'a' ],
            allowedAttributes: {
                'a': [ 'href' ]
            }
        })
    }

    onChangeGreeting = (content) => {
        this.setState({newGreeting: this.filterGreeting(content)});
    }

    onSubmitModifyDetails = async (ev) => {

        ev.preventDefault();

        const request = {};
        request.greeting = this.state.galery.greeting;
        if (this.state.newGreeting) {
            request.greeting = this.filterGreeting(this.state.newGreeting);
        }

        const els = ev.target.elements;
        const fields = ['title','subtitle','pagetitle','digital_min_amount','digital_only_mincount'];
        for (let field of fields) {
            request[field] = els[field].value;
        }

        try {
            await axios.put(process.env.REACT_APP_API_URL + "/galery/" + this.galeryId, request,this.axiosConfig())
            this.notify('A galéria adatainak módosítása sikeres volt');
            this.setState({newGreeting: null})
        } catch (e) {
            this.handleError(e);
        }
    }

    updateSizes = () => {
        this.setState({sizesLoading: true}, async() => {
            try {
                let result = await axios.get(process.env.REACT_APP_API_URL + '/galery/' + this.galeryId + '/sizes',this.axiosConfig())
                this.setState({sizesLoading: false, sizes: result.data.data })
            } catch (e){
                this.handleError(e);
                this.setState({sizesLoading: false});
            }
        })
    }

    onNewSize = async (data) => {
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/galery/' + this.galeryId + '/sizes', data,this.axiosConfig())
            this.notify('Az új méret hozzáadva: '+data.size);
        } catch (e) {
            this.handleError(e);
        }
        this.updateSizes();
    }

    onDeleteSize = async (id)=> {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + '/galery/' + this.galeryId + '/sizes/' + id,this.axiosConfig())
            this.notify('Az méret törlése sikerült');
        } catch (e) {
            this.handleError(e);
        }
        this.updateSizes();
    }

    onModifySize = (id)=> {
        this.setState({modifySizeId: id})
    }

    onSaveSize = (id,data) => {
        this.setState((state) => {
            state.modifySizeId= null;
            Object.assign(state.sizes.find((it) => it.id===id),data);
        }, async () => {
            try {
                await axios.put(process.env.REACT_APP_API_URL + '/galery/' + this.galeryId + '/sizes/' + id, data,this.axiosConfig())
                this.notify('A méret módosítása sikerült: '+data.size);
            } catch (e) {
                this.handleError(e);
            }
            this.updateSizes()
        })
    }

    onCancelModifySize = () => {
        this.setState({modifySizeId: null}, () => {
            this.updateSizes()
        })
    }

    render() {
        if (this.state.loadFailed){
            return <Error404AdminView />
        }
        if (!this.state.loaded) {
            return null;
        }
        return (
            <GaleryView {...this.state}
                        imagesBase={process.env.REACT_APP_API_URL+"/"}
                        onFilesChange={this.onFilesChange}
                        onUpload={this.onUpload}
                        onDelete={this.onDelete}
                        onDeleteAll={this.onDeleteAll}
                        onChangeGreeting={this.onChangeGreeting}
                        onSubmitModifyDetails={this.onSubmitModifyDetails}
                        greeting={this.state.greeting}
                        newGreeting={this.state.newGreeting}
                        sizes={this.state.sizes}
                        onNewSize={this.onNewSize}
                        onDeleteSize={this.onDeleteSize}
                        onModifySize={this.onModifySize}
                        onSaveSize={this.onSaveSize}
                        onCancelModifySize={this.onCancelModifySize}
                        modifySizeId={this.state.modifySizeId}
            />
        );
    }
}

export default GaleryPage;